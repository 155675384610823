<template>
    <SinglePage>
      <div class="grid-x align-center">
        <div class="cell small-12 medium-7" style="display:grid; place-content:center;max-width:400px;">
            <h1 class="center" style="text-align: center; ">
                Prova BytBostad <strong style="color:#FF7765;">gratis</strong>!</h1>

                <p>Bor du för trångt? För dyrt? För långt från jobbet? På <strong>BytBostad</strong> hittar du <strong>lägenhetsbytet</strong> så att du kan byta upp dig!</p>
                
                <p style="background-color: #64bcb254;padding:1rem;border-radius:0.3rem;margin-bottom:1rem;"><strong>OBS</strong>: För att använda tjänsten behöver du ha ett <strong>förstahandskontrakt</strong> att byta bort.</p>

                <FBButton v-if="!user" />

                
            </div>
      </div>
    </SinglePage>
  </template>
  
  <script>
  import { mapState, mapGetters } from 'vuex'
  import SinglePage from '@/components/SinglePage.vue'
  import FBButton from '@/components/FBButton.vue'

  export default {
    computed: {
    ...mapState(['categories']),
    ...mapGetters(['token', 'user', 'myAds'])
  },
    components: {
      SinglePage,
      FBButton
    },
    mounted () {
      setCookie('referer', 'rk_2410', 365);
    },
    watch:{
    user: {
      handler () {
        // console.log('USER: ', this.user);
        if (this.user) {
          this.$router.push({ name: 'create-ad' })
          // this.username = ''
          // this.password = ''
        }
      }
    } }
  }

  // Function to create a cookie
function setCookie(name, value, daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
  </script>
  