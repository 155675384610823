import Vue from 'vue'
import Vuex from 'vuex'
import AdService from '@/services/AdService.js'
import UserService from '@/services/UserService.js'
import axios from 'axios'
import apiClient from '@/services/API'
import router from './router'
// import imagePreloader from 'vue-image-preloader'
// Vue.use(imagePreloader)
// import prefetchImages from 'prefetch-image'
import Preload from 'image-preload'
import * as Sentry from '@sentry/browser'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    referer: '',
    user: false,
    categories: ['Hyresrätt', 'Bostadsrätt'],
    rooms: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    showFilters: false,
    prospects: [],
    prospectsLoading: false,
    prospectsPhotos: [],
    myAds: [],
    listenToKeyboard: false,
    prospectModal: false,
    adDetails: false,
    loginstatus: '',
    apiBase:
      localStorage.getItem('apiBase') || 'https://api.bytbostad.se/api/v1',
    token: localStorage.getItem('token') || '',
    filters: false,
    locations: [],
    fbSdkReady: false,
    fbConnected: false,
    fbRefused: false,
    fbFirstName: '',
    matches: [],
    history: [],
    memberships: [],
    outOfMatches: false,
    showMoreMenu: false,
    frontPage: []
  },
  mutations: {
    REMOVE_PROSPECT(state, id) {
      // state.prospects.shift()
      // console.log(state.prospects)
      const newProspects = state.prospects.filter(
        prospect => prospect.id !== id
      )
      // console.log(newProspects)
      state.prospects = newProspects
    },
    // REMOVE_PROSPECT(state) {
    //   state.prospects.shift()
    // },
    AUTH_LOGOUT(state) {
      state.loginstatus = ''
      state.token = false
      state.prospects = []
      state.user = false
      state.filters = false
      state.myAds = []
      state.matches = []
      state.history = []
      state.memberships = []
      delete apiClient().defaults.headers.common['Token']
      localStorage.removeItem('token')
      localStorage.removeItem('me')
    },
    AUTH_REQUEST(state) {
      state.loginstatus = 'loading'
    },
    AUTH_ERROR(state) {
      state.loginstatus = 'error'
      state.token = false
    },
    AUTH_SUCCESS(state, token) {
      apiClient().defaults.headers.common['Token'] = token
      localStorage.setItem('token', token)
      state.loginstatus = 'success'
      state.token = token
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_REFERER(state, referer) {
      state.referer = referer
    },
    OUT_OF_MATCHES(state, outOfMatches) {
      state.outOfMatches = outOfMatches
    },
    SET_MEMBERSHIPS(state, memberships) {
      state.memberships = memberships
    },
    SET_MATCHES(state, matches) {
      state.matches = matches
    },
    SET_FRONTPAGE(state, items) {
      state.frontPage = items
    },
    SET_HISTORY(state, history) {
      state.history = history
    },
    SET_AD_DETAILS(state, ad) {
      state.adDetails = ad
    },
    SET_PROSPECTS_LOADING(state, value) {
      state.prospectsLoading = value
    },
    SET_PROSPECT_MODAL(state, value) {
      console.log('Setting prospect modal to ', value)
      state.prospectModal = value
    },
    // SET_PROSPECT_PHOTOS(state, value) {
    //   state.prospectsLoading = value
    // },
    SET_LOCATIONS(state, locations) {
      state.locations = locations
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    LISTEN_TO_KEYBOARD(state, value) {
      state.listenToKeyboard = value
    },
    SET_MORE_MENU(state,value){
      console.log('State setting showMore to ',value)
      state.showMoreMenu = value
    },
    TOGGLE_MORE_MENU(state) {
      state.showMoreMenu = !state.showMoreMenu
    },
    TOGGLE_FILTERS(state) {
      state.showFilters = !state.showFilters
    },
    SET_TOGGLE_FILTERS(state, value) {
      state.showFilters = value
    },
    SET_PROSPECTS(state, prospects) {
      state.prospects = prospects
    },
    SET_API(state, apiBase) {
      localStorage.setItem('apiBase', apiBase)
      state.apiBase = apiBase
    },
    SET_MY_ADS(state, myAds) {
      state.myAds = myAds
    },
    SET_FB_SDK(state, sdk) {
      state.fbSdkReady = sdk
    },
    SET_FB_CONNETED(state, value) {
      state.fbConnected = value
    },
    SET_FB_REFUSED(state, value) {
      state.fbRefused = value
    },
    SET_FB_FIRSTNAME(state, value) {
      state.fbFirstName = value
    }
  },
  actions: {
    myAction(context, { vm }) {
      vm.$notify()
    },
    preLoadImages({ commit }, images) {
      // commit('SET_PROSPECTS_LOADING', true)
      return new Promise((resolve, reject) => {
        Preload(images.slice(0, 3), {
          inBackground: false,
          toBase64: false,
          // onSingleImageComplete: res => console.log(res.path[0].currentSrc),
          onComplete: () => resolve(commit('SET_PROSPECTS_LOADING', false))
        })
      })
    },
    likeProspect({ commit }, id) {
      return new Promise((resolve, reject) => {
        // console.log(id)
        resolve()
      })
    },
    getMemberships({ commit }) {
      return new Promise((resolve, reject) => {
        UserService.getMemberships()
          .then(response => {
            commit('SET_MEMBERSHIPS', response.data.data)
            resolve()
          })
          .catch(error => {
            commit('SET_MEMBERSHIPS', [])
            reject(error)
          })
      })
    },

    cancelMemberships({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        UserService.cancelMembership().then(() => {
        // this.memberships =res.data.data
        // console.log(res)
          dispatch('getMemberships')
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },

    deleteUser({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        UserService.deleteUser().then(response => {
        // this.memberships =res.data.data
        // console.log(response)
        // dispatch('getMemberships')
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },

    getMyAds({ commit }) {
      return new Promise((resolve, reject) => {
        AdService.myAds()
          .then(response => {
            commit('SET_MY_ADS', response.data.data)
            resolve()
          })
          .catch(error => {
            commit('SET_MY_ADS', [])
            reject(error)
          })
      })
    },
    setUser({ commit }, user) {
      return new Promise(resolve => {
        commit('SET_USER', user)
        resolve()
      })
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit('AUTH_LOGOUT')
        commit('SET_USER', false)

        FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            FB.logout(function(response) {
              // this part just clears the $_SESSION var
              // replace with your own code
              // console.log('Loggade ut med FB från Store')
              commit('SET_FB_CONNETED', false)
            })
          }
        })
        commit('SET_FB_CONNETED', false)
        router.push({ name: 'login' }).catch(err => {
          // console.log(err)
        })
        resolve()
      })
    },
    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit('AUTH_REQUEST')
        axios({
          url: this.state.apiBase + '/../../auth/',
          data: user,
          method: 'POST',
        })
          .then(resp => {
            const token = resp.data.data.token
            commit('AUTH_SUCCESS', token)
            dispatch('getMe')
            dispatch('getFilters')
            dispatch('getMyAds')
            dispatch('getMemberships')
            resolve(resp)
          })
          .catch(err => {
            // console.log('Fel')
            // console.log(err)
            commit('AUTH_ERROR', err)
            // commit('AUTH_LOGOUT')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    loginFB({ commit, dispatch }, fbtoken) {
      return new Promise((resolve, reject) => {
        commit('AUTH_REQUEST')
        axios({
          url: this.state.apiBase + '/../../fb-auth',
          params: { access_token: fbtoken },
          // data: user,
          method: 'GET'
        })
          .then(resp => {
            // console.log('Logged in')
            // console.log(resp.data)
            const token = resp.data.data.token
            commit('AUTH_SUCCESS', token)
            dispatch('getMe')
            dispatch('getFilters')
            dispatch('getMyAds')
            dispatch('getMemberships')
            resolve(resp)
          })
          .catch(err => {
            // console.log('Fel')
            // console.log(err)
            commit('AUTH_ERROR', err)
            // commit('AUTH_LOGOUT')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    newPW({ commit, dispatch }, codeAndEmail) {
      return new Promise((resolve, reject) => {
        // console.log(codeAndEmail)
        // resolve(codeAndEmail)
        axios({
          url: this.state.apiBase + '/newpw',
          data: codeAndEmail,
          method: 'POST'
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },

    resetpw({ commit, dispatch }, email) {
      return new Promise((resolve, reject) => {
        // commit('AUTH_REQUEST')
        axios({
          url: this.state.apiBase + '/resetpw',
          data: email,
          method: 'POST'
        })
          .then(resp => {
            // commit('AUTH_SUCCESS', token)
            // dispatch('getMe')
            // dispatch('getFilters')
            // dispatch('getMyAds')
            // console.log(resp)
            resolve(resp)
          })
          .catch(err => {
            // console.log('Fel')
            // console.log(err)
            // commit('AUTH_ERROR', err)
            // commit('AUTH_LOGOUT')
            // localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    registerEmail({ commit, dispatch }, email) {

      const ref = getCookie('referer');
      if(ref){
        commit('SET_REFERER', ref)  
      }else{
        commit('SET_REFERER', '')
      }
     
      
      console.log('Store registering ', email, this.state.referer)
      return new Promise((resolve, reject) => {
        console.log('Store registering inside promise ', email, this.state.referer)
        console.log('URL: ', this.state.apiBase + '/registeremail')
        // commit('AUTH_REQUEST')
        axios({
          url: this.state.apiBase + '/registeremail',
          data: { email, referer: this.state.referer },
          method: 'POST'
        })
          .then(resp => {
            // commit('AUTH_SUCCESS', token)
            // dispatch('getMe')
            // dispatch('getFilters')
            // dispatch('getMyAds')
            // console.log('Register success')
            // console.log(resp.data.data)
            const token = resp.data.data.token
            commit('AUTH_SUCCESS', token)
            // commit('AUTH_SUCCESS', token)
            dispatch('getMe')
            dispatch('getFilters')
            dispatch('getMyAds')
            dispatch('getMemberships')
            resolve(resp.data.data)

            // resolve(resp)
          })
          .catch(err => {
            // console.log('Fel
            console.log('Register fail')
            console.log(err)
            // commit('AUTH_ERROR', err)
            // commit('AUTH_LOGOUT')
            // localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    getMe({ commit }) {
      return new Promise((resolve, reject) => {
        UserService.getMe()
          .then(response => {
            commit('SET_USER', response.data.data)
            localStorage.setItem('me', JSON.stringify(response.data.data))
            Sentry.configureScope(function(scope) {
              scope.setUser({ 'email': response.data.data.email })
            })
            resolve(response.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    setFilters({ commit }, filters) {
      return new Promise((resolve, reject) => {
        UserService.updateFilters(filters)
          .then(response => {
            commit('SET_FILTERS', response.data.data)
            // dispatch('updateProspects')
            resolve()
          })
          .catch(error => {
            Sentry.captureException(error);
            reject()
          })
      })
    },

    updateMe({ commit }, user) {
      return new Promise((resolve, reject) => {
        UserService.updateMe(user)
          .then(response => {
            // commit('SET_USER', response.data.data)
            resolve()
          })
          .catch(error => {
            Sentry.captureException(error);
            reject()
          })
      })
    },

    getLocations({ commit }) {
      UserService.getLocations()
        .then(response => {
          commit(
            'SET_LOCATIONS',
            response.data.data // .map(p => (p.isShowing = true))
          )
        })
        .catch(error => {
          Sentry.captureException(error);

        })
    },

    getFilters({ commit }) {
      UserService.getFilters()
        .then(response => {
          commit(
            'SET_FILTERS',
            response.data.data // .map(p => (p.isShowing = true))
          )
        })
        .catch(error => {
          Sentry.captureException(error);

        })
    },

    getMatches({ commit }, adId) {
      return new Promise((resolve, reject) => {
        AdService.getMatches(adId)
          .then(response => {
            commit(
              'SET_MATCHES',
              response.data.data // .map(p => (p.isShowing = true))
            )
            // console.log(response.data.data)
            resolve()
          })
          .catch(error => {
            Sentry.captureException(error);
            reject()
          })
      })
    },
    getFrontpage({ commit }) {
      return new Promise((resolve, reject) => {
        AdService.getFrontpage()
          .then(response => {

            console.log('Frontpage response', response.data.data)
            commit(
              'SET_FRONTPAGE',
              response.data.data // .map(p => (p.isShowing = true))
            )
            // console.log(response.data.data)
            resolve()
          })
          .catch(error => {
            Sentry.captureException(error);
            reject()
          })
      })
    },

    getHistory({ commit }, adId) {
      return new Promise((resolve, reject) => {
        AdService.getHistory(adId)
          .then(response => {
            commit(
              'SET_HISTORY',
              response.data.data.map((x) => { 
                return { 
                  id: x[0], 
                  interest: x[1],
                  rooms: x[2],
                  size: x[3],
                  rent: x[4],
                  city: x[5], 
                  address: x[6], 
                  image: x[7], 
                }
              }) // .map(p => (p.isShowing = true))
            )
            // console.log(response.data.data)
            resolve()
          })
          .catch(error => {
            Sentry.captureException(error);
            reject()
          })
      })
    },

    updateProspects({ commit }) {
      return new Promise((resolve, reject) => {
        // commit('SET_PROSPECTS', [])
        // console.log('updateProspects in store is setting the loading to true')
        // commit('SET_PROSPECTS_LOADING', true)
        AdService.getProspects()
          .then(response => {
            if (response.data.data.length < 1) {
              commit('OUT_OF_MATCHES', true)
            } else {
              commit('OUT_OF_MATCHES', false)
            }
            commit(
              'SET_PROSPECTS',
              response.data.data // .map(p => (p.isShowing = true))
            )
            resolve()
          })
          .catch(error => {
            // console.log('There was an error:', error.response)

            reject(error.response)
          })
      })
    }
  },
  getters: {
    showMoreMenu: state => {
      return state.showMoreMenu
    },
    outOfMatches: state => {
      return state.outOfMatches
    },
    locations: state => {
      return state.locations
    },
    memberships: state => {
      return state.memberships
    },
    activeMemberships: state => {
      // membershipIsActive(membership){
      //   return membership.end_date == null || new Date() < new Date(membership.end_date)
      // },
      // return state.memberships
      return state.memberships ? state.memberships.filter(membership => membership.end_date == null || new Date() < new Date(membership.end_date)) : []
    },
    matches: state => {
      return state.matches
    },
    history: state => {
      return state.history
    },
    fbFirstName: state => {
      return state.fbFirstName
    },
    fbRefused: state => {
      return state.fbRefused
    },
    fbConnected: state => {
      return state.fbConnected
    },
    fbSdkReady: state => {
      return state.fbSdkReady
    },
    adDetails: state => {
      return state.adDetails
    },
    prospectModal: state => {
      return state.prospectModal
    },
    prospectsPhotos: state => {
      return state.prospects.map(ad => ad.photo)
    },
    prospectsLoading: state => {
      return state.prospectsLoading
    },
    myAds: state => {
      return state.myAds
    },
    frontPage: state => {
      return state.frontPage
    },

    myActiveAd: state => {
      if (state.myAds && state.myAds.length > 0) {
        return state.myAds[0].id
      } else {
        return false
      }
    },
    hasAds: state => {
      if (state.myAds && state.myAds.length > 0) {
        return true
      } else {
        return false
      }
    },
    loginstatus: state => {
      return state.loginstatus
    },
    showFilters: state => {
      return state.showFilters
    },
    apiBase: state => {
      return state.apiBase
    },
    token: state => {
      return state.token
    },
    catLength: state => {
      return state.categories.length
    },
    prospects: state => {
      return state.prospects
    },
    currentAd: state => {
      return state.prospects[0]
    },
    listenToKeyboard: state => {
      return state.listenToKeyboard
    },
    user: state => {
      return state.user
    },
    filters: state => {
      return state.filters
    }
  }
})


// Function to get a cookie by name
function getCookie(name) {
  const nameEQ = name + "=";
  const cookiesArray = document.cookie.split(';');
  for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i];
      while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
          return cookie.substring(nameEQ.length, cookie.length);
      }
  }
  return null;
}
