<template>
  <div id="app">

  <!--

    <vue-cookie-toggler
    v-if="
          ![
            'cookies'
          ].includes($route.name)
        "
      :cookies-groups="cookiesGroups"
      title="Vi använder cookies"
      settings-title="Cookie settings title"
      settings-label="Settings button label"
      accept-label="Accept button label"
      save-label="Save button label"
      cancel-label="Cancel button label"
    >


    <p>
      På BytBostad använder vi cookies både från vår egen webbplats
    och från externa parter för att erbjuda dig en optimal användarupplevelse,
    analysera trafik och presentera skräddarsytt innehåll. Genom att fortsätta
    använda vår webbplats samtycker du till användningen av cookies för dessa syften.
  </p>
  <p>Läs mer i vår  <router-link :to="{ name: 'cookies' }">
                  cookiepolicy
                </router-link></p>


      <template #settingsContent> Cookie settings message </template>

      <template #mainButtons="{ accept, settings }">
        <button class="vct__btn vct__btn--default" @click="settings">
          Ändra inställningar
        </button>
        <button class="vct__btn vct__btn--primary" @click="accept">
          Jag accepterar
        </button>
      </template>

      <template #settingsButtons="{ accept, save }">
        <button class="vct__btn vct__btn--default" @click="accept">
          Acceptera alla cookies
        </button>
        <button class="vct__btn vct__btn--primary" @click="save">
          Spara
        </button>
      </template>
    </vue-cookie-toggler>
   -->

    <div v-cloak>
      <NavBar />
      <router-view />
      <Footer
        v-if="
          [
            'home',
            'faq',
            'login',
            'ad',
            'my-account',
            'matches',
            'create-ad',
            'about-us',
            'jobs',
            'terms',
            'history',
            'lagenhetsbyte',
            'cookies',
          ].includes($route.name)
        "
      />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

import VueCookieToggler from 'vue-cookie-toggler';

// import HelloI18n from './components/HelloI18n'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavBar,
    Footer,
    VueCookieToggler
  },
  data(){
    return {
      cookiesGroups: [
        {
          category: 'essential',
          name: 'Nödvändiga',
          description:
            'Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.',
          active: true,
          required: true,
        },
        {
          category: 'analytics',
          name: 'Analys och marknadsföring',
          description:
            'Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.',
          active: false,
        },
        // {
        //   category: 'social_networking',
        //   name: 'Social networking',
        //   description:
        //     'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.',
        //   active: false,
        // },
      ],
    }
  },

  computed: { ...mapGetters(['token', 'myAds', 'myActiveAd']) },

  watch: {
    myAds: {
      handler() {
        if (this.myActiveAd) {
          // console.log(this.myActiveAd)
          this.$store.dispatch('getMatches', this.myActiveAd)
          // this.$router.push({ name: 'ad', params: { id: this.myActiveAd } })
        }

        if (this.myAds.length > 1) {
          // console.log('Du har flera annonser')
        }
      }
    }
  },
  mounted() {

    console.log('Mounted app');
    this.$store.dispatch('getFrontpage')
    // this.$store.displatch('getFrontpage')

    // console.log('Mounted...')
    if (this.token) {
      this.$store
        .dispatch('getMe')
        .then(res => {
          // console.log('Found me!')
          // console.log(res)
          this.$store.dispatch('getFilters')
          this.$store.dispatch('getMemberships')
          this.$store.dispatch('getMyAds')
          // this.$store.dispatch('getMatches')
          // this.$store.dispatch('getMatches')
        })
        .catch(err => {
          Sentry.captureException(err)
          this.$router.push({ name: 'login' })
          // console.log('Did not find me!')
          // console.log(err)
        })
    }
  },
  metaInfo() {
    return {
      // Children can override the title.
      title: 'Lägenhetsbyte 🏠↔🏠',
      // Result: My Page Title ← My Site
      // If a child changes the title to "My Other Page Title",
      // it will become: My Other Page Title ← My Site
      titleTemplate: '%s - BytBostad',
      // Define meta tags here.
      meta: [
        // {http-equiv: 'Content-Type', content: 'text/html; charset=utf-8'},
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' },

        {
          vmid: 'og:image',
          property: 'og:image',
          name: 'image',
          content: 'https://bytbostad.se/img/share.jpg'
        },
        { property: 'fb:app_id', content: '162588207446399' },
        { vmid: 'og:type', property: 'og:type', content: 'website' }
      ]
    }
  }
}
</script>

<style lang="scss">
// @import '../styles/index.scss';
@import 'scss/main.scss';
[v-cloak] {
  display: none !important;
}
html {
  touch-action: manipulation;
}
.v--modal-overlay{
  z-index: 99999;
}
.v--modal{
  border-radius:1rem;
  overflow:hidden;
  font-family: "Nunito", sans-serif;
  font-weight: 600;;
}
#cookieConsentModal .cookieConsent-modal{
  font-family: "Nunito", sans-serif;

}
.cookie-consent_modal, .cookieConsent-modal__body, .cookie-consent_modal__header-title{
  font-family: "Nunito", sans-serif;
}

#cookieConsentModal {
  font-family: "Nunito", sans-serif!important;
  font-weight: 600!important;
  // color:red;
}
.cookie-consent_modal .vct__btn {
  font-family: "Nunito", sans-serif;
}

#cookieConsentModal > div > div.cookie-consent_modal__footer > button.vct__btn.vct__btn--default{
  font-family: "Nunito", sans-serif;
  font-weight: 600;;
}

#cookieConsentModal > div > div.cookie-consent_modal__footer > button.vct__btn.vct__btn--primary{
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}


.cookieConsent-modal__body p {
  padding-bottom:20px;
  line-height: 1.5;
}

</style>
