<template>
  <SinglePage>
    <div class="grid-x align-center">
      <div class="cell small-12 medium-7 terms">
        <article>
          <h1>Cookiepolicy - BytBostad.se</h1>

          <h2>Vad är cookies?</h2>

          <p>
            Cookies är små textfiler som sparas i din webbläsare, dessa
            textfiler innehåller viss information som din webbläsare använder
            för att avgöra vad som skall synas på sajten.
          </p>

          <p>
            Alla sajter har möjlighet att spara cookies i din webbläsare, men
            bara sajten som skapat cookien har möjlighet att läsa informationen
            i den.
          </p>

          <h2>Hur använder BytBostad cookies?</h2>

          <p>
            Vi använder oss av funktionella cookies, som t.ex. används för att veta
            vilken användare det är som är inloggad, vilka inställningar du som
            användare har gjort, vilken språk sajten skall visas på etc.
          </p>

          <p>
            Vi använder oss också av analys- och marknadsförings-cookies för att kunna mäta traffiken på sajten, och
            förstå hur sajten används, för att kunna förbättra användarupplevelsen och 
            förstå hur våra besökare använder sajten.
          </p>

          <p>
            Vi använder oss också av tredjepartstjänster som t.ex. Google Analytics, Google Tag
            Manager, Facebook mm. för att kunna analysera och användarbetteende och mäta 
          </p>
        
        
        </article>
      </div>
    </div>
  </SinglePage>
</template>

<script>
import SinglePage from '@/components/SinglePage.vue'
export default {
  components: {
    SinglePage,

  }
}
</script>

<style scoped>


</style>
